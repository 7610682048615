// 判断是否为竖屏
export function isLineScreen() {
    return window.innerWidth < window.innerHeight
}
// 获取图片应设置高度
export function getImageHeight(){
    const is_line = isLineScreen()
    return is_line ? window.innerWidth : window.innerHeight
}
// 函数节流
export function throttle(fn, delay) {
    let timer;
    return function() {
        if (!timer) {
            timer = setTimeout(() => {
                fn();
                clearTimeout(timer);
                timer = null;
            }, delay)
        }
    }
}
export function isIOS(){
    let u = navigator.userAgent;
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    return isiOS;
}
export function isAndroid() {
    const u = navigator.userAgent;
    if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) {
        return true
    } else {
        return false
    }
}
export function isQQBrowser() {
    const ua = window.navigator.userAgent.toLowerCase();
    return ua.indexOf('mqqbrowser') > -1
}
export function  isWeChat(){
    const ua = window.navigator.userAgent.toLowerCase();
    if(ua.match(/MicroMessenger/i) == 'micromessenger'){
        return true;
    }else{
        return false;
    }
}
