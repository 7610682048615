<template>
  <div id="app">
    <cover-page @handlePlay="handlePlay" :visible="showCover" :loaded="loaded" />
    <content-page :visible="!showCover" :loaded="loaded" />
  </div>
</template>

<script>
import CoverPage from './components/CoverPage'
import ContentPage from "@/components/ContentPage";
import 'animate.css/source/animate.css';

export default {
  name: 'App',
  components: {
    CoverPage,ContentPage,
  },
  data(){
    return {
      showCover: true,
      show: false,
      loaded: false,
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.init()
  },
  methods: {
    init(){
      const _this = this
      window.onload = function(){
        _this.loaded = true
      }
      window.addEventListener('orientationchange',function(){
        setTimeout(()=>{
          _this.loaded = false
          document.querySelector(".content").scrollLeft = 0
          document.querySelector("html").scrollTop = 0
          location.reload()
        },800)
      })
    },
    handlePlay(){
      this.showCover = false
    },
    play(){
      document.querySelector("#p1").play()
    }
  }
}
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>
