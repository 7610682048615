<template>
  <div class="slider-menu" :style="isLine ? 'top: 25px; right: 25px;left: auto; transform: rotate(90deg);' : ''">
    <i v-if="active">
      <img src="../assets/images/icon_music.png" class="play-active" id="autoplay" @click="(e)=>handleChange(false,e)" />
    </i>
    <i v-else>
      <img src="../assets/images/icon_music_disabled.png" class="" id="autoplay" @click="(e)=>handleChange(true,e)" />
    </i>
  </div>

</template>

<script>
import {isLineScreen} from "@/utils/utils";

export default {
  name: "SliderMenu",
  data(){
    return {
      active: false,
      isLine: isLineScreen()
    }
  },
  methods: {
    handleChange(state,e){
      e.preventDefault()
      // if(this.active && !state) {
      //   this.pauseAll()
      // }else if(!this.active && state){
      //    this.$emit('rePlay',true)
      // }
      this.active = state
      this.$emit('stateChange',state)
    },
    setAutoPlay() {
      this.active = true
    },
    pauseAll() {
      this.active = false;
      // document.querySelectorAll("audio").forEach(item=>{
      //   if(!item.paused) {
      //     item.pause()
      //   }
      // })
    }
  }
}
</script>

<style scoped>
.slider-menu {
  position: fixed;
  z-index: 20;
  top: 25px;
  left: 25px;
}
.slider-menu img {
  width: 35px;
}
@keyframes rotateRound {
  100% { transform: rotate(360deg); }
}
.play-active {
  animation: rotateRound 3s infinite;
  animation-timing-function: cubic-bezier(0.18, 0.15, 0.6, 0.7);
}

</style>
