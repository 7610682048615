<template>
  <transition :leave-active-class="isLine ? 'cover fadeOutUp' : 'cover fadeOutLeft'" >
    <div class="loading-wrap" v-if="visible">
      <div class="loading-content" v-show="!loaded">
        <div class="loading-icon">
          <img src="../assets/images/loading.png" />
        </div>
        <p class="loading-font">加载中...</p>
      </div>
      <div class="play-wrap" v-show="loaded" :class="isLine ? 'rotate' : ''">
        <img src="../assets/images/cover.jpg" class="cover-img" :height="height"/>
        <div style="width: 80%">
          <a id="play" class="play-btn" ><img src="../assets/images/tips.png" @click="handlePlay"></a>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {getImageHeight, isLineScreen} from "@/utils/utils";

export default {
  name: "CoverPage",
  data(){
    return {
      height: getImageHeight(),
      isLine: isLineScreen()
    }
  },
  activated() {

  },
  methods: {
    handlePlay(){
      window.scrollTo(0, 0);
      this.$emit('handlePlay')
    }
  },
  props: {
    // 隐藏cover页
    visible: {
      default: true
    },
    // 加载完毕
    loaded: {
      default: false
    }
  }
}
</script>

<style scoped>
.cover {
  animation-duration: 1s;
}
@keyframes play {
  0% { transform: translate(4px, 0); }
  25% { transform: translate(0, 0); }
  50% { transform: translate(4px, 0); }
  75% { transform: translate(0, 0); }
  100% { transform: translate(4px, 0); }
}
.gif-wrap {
  position: absolute;
  bottom: 0;
  left: 44%;
}
.play-btn {
  /*animation: play 3s infinite;*/
  display: flex;
  justify-content: center;
  text-align: center;
  color: #03a9f4;
  font-size: 20px;
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
}
.loading-wrap {
  background: #fff;
  overflow: hidden;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  z-index: 9999;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-content {
  text-align: center;
  margin: 0 auto;
}
.loading-font {
  margin: 15px 0;
  font-size: 1.1em;
  color: rgb(0,0,0,75%);
  font-weight: 600;
}
.loading-icon {
  animation: loadingShake 1s infinite;
}
.loading-icon img {
  width: 70px;
}
@keyframes loadingShake {
  0% { transform: translateY(8px); }
  50% { transform: translateY(0); }
  100% { transform: translateY(8px); }
}

</style>
