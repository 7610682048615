<template>
  <div class="content">
    <div class="border-img">
      <img src="../assets/images/slider_border.png" style="width: 100%;" />
    </div>
    <div class="slider-wrap">
      <slider   :indicators="false" :autoplay="autoplay" :interval="speed" :control-btn="false" @change="handleChange" ref="slider" >
        <slider-item v-for="(item,index) in bannerList" :key="index" class="slider-item" >
          <div style="text-align: center">
            <img :alt="item.title" :src="item.path" class="slider-img" />
          </div>
        </slider-item>
      </slider>
    </div>
  </div>
</template>

<script>
import { Slider, SliderItem } from 'vue-easy-slider'
export default {
  name: "SliderImg",
  components: {
    Slider,SliderItem,
  },
  data(){
    return {
      autoplay: true,
      speed: 900,
      bannerList: [
        {
          path: require('../assets/images/slider/1950.1.jpg'),
          year: 1950,
          title: '抗美援朝战争'
        },{
          path: require('../assets/images/slider/1950.2.jpg'),
          year: 1950,
          title: '新中国第一部法律《婚姻法》正式实施'
        },{
          path: require('../assets/images/slider/1954.jpg'),
          year: 1954,
          title: '第一届全国人民代表大会'
        },{
          path: require('../assets/images/slider/1956.jpg'),
          year: 1956,
          title: '中共八大召开'
        },{
          path: require('../assets/images/slider/1964.jpg'),
          year: 1964,
          title: '第一颗原子弹爆炸成功'
        },{
          path: require('../assets/images/slider/1970.jpg'),
          year: 1970,
          title: '“东方红一号”发射成功'
        },{
          path: require('../assets/images/slider/1971.jpg'),
          year: 1971,
          title: '我国在联合国合法席位恢复'
        },{
          path: require('../assets/images/slider/1973.jpg'),
          year: 1973,
          title: '袁隆平培育出世界第一代籼型杂交水稻——“东方魔稻”'
        },{
          path: require('../assets/images/slider/1978.jpg'),
          year: 1978,
          title: '十一届三中全会召开'
        },
		//{
        //  path: require('../assets/images/slider/1980.jpg'),
        //  year: 1980,
        //  title: '经济特区设立'
        //},{
        //  path: require('../assets/images/slider/1982.jpg'),
        //  year: 1982,
        //  title: '中共十二大召开，邓小平提出建设有中国特色社会主义'
        // },
      ]
    }
  },
  mounted() {
    this.init()
    this.handleChange(0)
  },
  methods: {
    init(){
      // const contentWidth = document.querySelector('[data-pn="i"]').clientWidth
      // console.log(contentWidth)
    },
    handleChange(e){
      const item = this.bannerList[e]
      this.$emit('onChange',item)
    },
    play() {
      console.log('slider init')
      this.value = 1
      this.$nextTick(()=>{
        this.speed = 900
      })
    },

  }
}
</script>
<style scoped>


.slider-img {
  width: auto;
  /*height: 258px;*/
}
.wrap div {
  width: 200px;
}
.slider-wrap {
  width: 84%;
  left: 8%;
  right: 0;
  /*top: 15%;*/
  position: absolute;
  z-index: 880 !important;
  overflow: hidden;
  margin-top: 7.4%;
  /*width: 200px;*/
  /*height: 50px;*/
  /*overflow: scroll;*/
}
.slider-item {
  transition: 3s;
  z-index: 880 !important;
}
.slider-item img {
  /*max-height: 280px;*/
  max-width: 100% !important;
}
@media (width: 375px) and (height: 667px) {
  .slider-item img {
    max-height: 250px;
  }
}
@media (height: 375px) and (width: 667px) {
  .slider-item img {
    max-height: 250px;
  }
}

@media (width: 390px) and (height: 844px) {
  .slider-item img {
    max-height: 260px;
  }
}
@media (height: 390px) and (width: 844px) {
  .slider-item img {
    max-height: 260px;
  }
}
@media (width: 414px) and (height: 896px) {
  .slider-item img {
    max-height: 31vh;
  }
}
@media (height: 414px) and (width: 896px) {
  .slider-item img {
    max-height: 31vh;
  }
}
@media (width: 768px) and (height: 1024px)  {
  .slider-item img {
    max-height: 50vh;
  }
}




.slider-indicators {
  display: none !important;
}
.slider {
  height: 70vh !important;
}
.border-img {
  position: absolute;
  overflow: hidden;
  z-index: 9990;
}
.content {
  position: absolute;
  width: 90%;
  top: 2%;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 200;
  /*background: #eee;*/
}
</style>
