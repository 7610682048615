<template>
    <div class="content"  :style="isLine ? '' : 'overflow-y: hidden;overflow-x: scroll;'" >
      <audio src="../assets/video/bgm1.mp3"  id="bgm1" hidden loop data-type="bgm"  />
      <audio src="../assets/video/bgm2.mp3"  id="bgm2" hidden loop data-type="bgm"  />
<!--      <auto-play ref="controls" @autoPlay="autoPlay" />-->
      <slider-menu ref="slider" @stateChange="videoStateChange" />
      <div class="content-wrap" :class="isLine ? 'rotate' : ''"  >
        <div class="page-item first-item" data-pn="a"   >
          <img src="../assets/images/0901580ddd517153_01.png" :height="height">
          <div class="font-wrap"  data-animate="fadeInDown">
            <img src="../assets/images/0901580ddd517153_01_font.png">
          </div>
          <div class="play-icon1">
            <img src="../assets/images/playing.gif" @click="(e)=>playById('v1',e)" />
            <audio src="../assets/video/v1_tap.mp3"  id="v1" hidden  />
          </div>
        </div>
        <div class="page-item" data-pn="b"  >
          <img src="../assets/images/0901580ddd517153_02.png" :height="height">
          <div class="font-wrap"  data-animate="fadeInDown">
            <img src="../assets/images/0901580ddd517153_02_font.png" >
          </div>
        </div>
        <div class="page-item" data-pn="c"  >
          <img src="../assets/images/0901580ddd517153_03.png" :height="height">
          <div class="font-wrap"  data-animate="fadeInDown">
            <img src="../assets/images/0901580ddd517153_03_font.png" >
          </div>
        </div>
        <div class="page-item" data-pn="d">
          <img src="../assets/images/0901580ddd517153_04.png" :height="height">
          <div style="position: absolute;top: 0;right: 0;">
            <img src="../assets/images/plan.gif" :height="height">
          </div>
          <div class="font-wrap" data-animate="fadeInDown">
            <img src="../assets/images/0901580ddd517153_04_font.png" >
          </div>
          <div class="play-icon1" style="right:20%">
            <img src="../assets/images/playing.gif" @click="(e)=>playById('v3',e)" />
            <audio src="../assets/video/v2_tap.mp3"  id="v3" hidden   />
          </div>
        </div>
        <div class="page-item" data-pn="e">
          <img src="../assets/images/0901580ddd517153_05.png" :height="height">
          <div class="font-wrap"  data-animate="fadeInDown">
            <img src="../assets/images/0901580ddd517153_05_font.png" >
          </div>
        </div>
        <div class="page-item" data-pn="f">
          <img src="../assets/images/0901580ddd517153_06.png" :height="height">
          <div style="position: absolute;left: -58px;" :style="isLine ? 'top:0': 'top: -55px;'">
            <img src="../assets/images/flag.gif" height="1000" />
          </div>
          <div class="font-wrap"  data-animate="fadeInDown">
            <img src="../assets/images/0901580ddd517153_06_font.png" >
          </div>
        </div>
        <div class="page-item" data-pn="g">
          <img src="../assets/images/0901580ddd517153_07.png" :height="height">
          <div class="play-icon1" style="right: 53%;top: 20%;">
            <img src="../assets/images/playing.gif" @click="(e)=>playById('v7',e)" />
            <audio src="../assets/video/v3_tap.mp3" id="v7" hidden  />
          </div>
          <div class="font-wrap"  data-animate="fadeInDown">
            <img src="../assets/images/0901580ddd517153_07_font.png" >
          </div>
        </div>
        <div class="page-item" data-pn="ga" >
          <img src="../assets/images/0901580ddd517153_07_1.png" :height="height">
          <div class="font-wrap"  data-animate="fadeInDown">
            <img src="../assets/images/0901580ddd517153_07_1_font.png" >
          </div>
        </div>
        <div class="page-item" data-pn="h" data-type="auto" pn="2" :style="pn2Style">
          <img src="../assets/images/0901580ddd517153_08.png" :height="height">
          <audio src="../assets/video/ph_auto.mp3" data-play="auto" id="v8" hidden   />
          <div style="position: absolute;top: 0;" class="kaiguodd filter" data-animate="fade-filter">
            <img src="../assets/images/0901580ddd517153_08_bg.png" :height="height">
          </div>
          <div class="font-wrap"  data-animate="fadeInDown">
            <img src="../assets/images/0901580ddd517153_08_font.png" >
          </div>
        </div>
        <div class="page-item" data-pn="i" pn="2" :style="pn2Style">
<!--          <div class="step-title">{{activeSlider.year}}</div>-->
          <img src="../assets/images/0901580ddd517153_09.png" :height="height">
          <slider-img ref="swiper" @onChange="swiperChange" />
          <div class="font-wrap"  data-animate="fadeInDown">
          </div>
        </div>
        <div class="page-item" data-pn="ia" pn="2" :style="pn2Style">
          <img src="../assets/images/0901580ddd517153_11.png" :height="height">
          <div class="font-wrap"  data-animate="fadeInDown">
            <img src="../assets/images/0901580ddd517153_11_font.png" >
          </div>
        </div>
        <div class="page-item" data-pn="j" pn="2" :style="pn2Style">
          <img src="../assets/images/0901580ddd517153_12.png" :height="height">
          <div class="play-icon1" style="right: 6%;top: 46%;">
            <img src="../assets/images/playing.gif" @click="(e)=>playById('v10',e)" />
            <audio src="../assets/video/v4_tap.mp3"  id="v10" hidden  />
          </div>
          <div class="font-wrap"  data-animate="fadeInDown">
            <img src="../assets/images/0901580ddd517153_12_font.png" >
          </div>
        </div>
        <div class="page-item" data-pn="k" pn="2" :style="pn2Style">
          <img src="../assets/images/0901580ddd517153_13.png" :height="height">
          <div style="position: absolute;top: 0;right: -105px;">
            <img src="../assets/images/fire.gif" :height="height" />
          </div>
          <div class="font-wrap"  data-animate="fadeInDown">
            <img src="../assets/images/0901580ddd517153_13_font.png" >
          </div>
        </div>
        <div class="page-item" data-pn="l" pn="2" :style="pn2Style">
          <img src="../assets/images/0901580ddd517153_14.png" :height="height">
          <div class="item12-c1" id="ship1" data-animate="ship">
            <img src="../assets/images/0901580ddd517153_14_c1.png" :height="height">
          </div>
          <div class="item12-c1" id="ship2" data-animate="shipA">
            <img src="../assets/images/0901580ddd517153_14_c2.png" :height="height">
          </div>
          <div class="item12-c1" style="z-index: 2" pn="2">
            <img src="../assets/images/0901580ddd517153_14_c3.png" :height="height">
          </div>
          <div class="font-wrap" >
            <img src="../assets/images/0901580ddd517153_14_font.png" >
          </div>
        </div>
        <div class="page-item" data-pn="m"  pn="2" :style="pn2Style">
          <img src="../assets/images/0901580ddd517153_15.png" :height="height">
          <div class="font-wrap"  data-animate="fadeInDown">
            <img src="../assets/images/0901580ddd517153_15_font.png" >
          </div>
        </div>
        <div class="page-item" data-pn="n" data-type="auto" pn="2" :style="pn2Style">
          <img src="../assets/images/0901580ddd517153_16.png" :height="height">
          <audio src="../assets/video/pm_auto.mp3" id="v13" data-play="auto" hidden  />
          <div class="font-wrap"  data-animate="fadeInDown">
            <img src="../assets/images/0901580ddd517153_16_font.png" >
          </div>
        </div>
        <div class="page-item" data-pn="o" pn="2" :style="pn2Style">
          <img src="../assets/images/0901580ddd517153_17.png" :height="height">
          <div class="font-wrap"  data-animate="fadeInDown">
            <img src="../assets/images/0901580ddd517153_17_font.png" >
          </div>
        </div>

      </div>
      <template v-if="isLine">
        <div id="bgc" class="hidden" :style="{backgroundImage: `url(${bc2})`}" ></div>
      </template>
      <template v-if="!isLine">
        <div id="bgc" class="hidden" :style="{backgroundImage: `url(${bc1})`}" ></div>
      </template>
      <template v-if="showTap">
        <div :class="isLine ? 'tap-wrap-line' : 'tap-wrap'">
          <div>
            <div style="text-align: center" class="tap-icon">
              <img src="../assets/images/tap.png"  style="width: 60px;"  />
            </div>
            <div style="margin-top:-5px;text-align: center">
              <img src="../assets/images/tap0.png"  style="width: 50px;"  />
            </div>
          </div>
        </div>
      </template>
<!--      <div id="log"><p>log</p></div>-->
<!--      <FooterModal ref="fModal" />-->
    </div>
</template>

<script>
import {getImageHeight, isIOS, isLineScreen, isWeChat, isAndroid, throttle, isQQBrowser} from "@/utils/utils";
import SliderImg from "@/components/SliderImg";
import SliderMenu from "@/components/SliderMenu";
import bcImg1 from '@/assets/images/cc.gif'
import bcImg2 from '@/assets/images/cc_l.gif'

export default {
  name: "ContentPage",
  components: {
    SliderMenu,
    SliderImg,
  },
  directives: {
    scroll: {
      inserted: function (el, binding) {
        let f = function (evt) {
          if (binding.value(evt, el)) {
            window.removeEventListener('scroll', f)
          }
        }
        window.addEventListener('scroll', f)
      }
    }
  },
  data(){
    return {
      pn2Style: "display:none",
      offset: 0.05,     // 偏移系数
      bc1: bcImg1,
      bc2: bcImg2,
      showTap: true,    // 手势图标
      height: getImageHeight(),
      isLine: isLineScreen(),
      pageConf: {}, // 每块dom对应的尺寸信息
      pageOffset: {},
      visitedKey: {}, // 以dom key为键值的处理标记
      currentKey: '',
      videoMuted: false,  // 是否静音
      is_ios: isIOS(),
      activeSlider: {
        year: '',
        title: ''
      },
      showFooter: false,  // 显示尾部封面
      videoManager: null,
      videoCurrent: false,
      bgmManager: null,
      char: ['a','b','c','d','e','f','g','ga','h','i','ia','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'],
      isPlaying: false, // 是否有非背景音乐的视频在播放
      videoInterval: null,  // 控制渐进式播放的flag
      lastPlaySrc: null,
      tapManager: null,  // 手动播放
      oldSize: 0,       // 判断滚动是否停止
    }
  },
  mounted() {
    const isLine = this.isLine
    document.getElementById("bgc").classList.add(isLine ? "bgc-line" : 'bgc')
    document.querySelectorAll(".page-item").forEach(item=>{
      item.style.height = this.height + 'px'
    })
    const _this = this
    document.querySelectorAll('[data-type="auto"]').forEach(item=>{
      item.addEventListener('touchstart',function(){
        // console.log('touch',item)
        const manager_src = _this.videoManager ? _this.videoManager.getAttribute("src") : ''
        const el = item.querySelector("audio")
        if((el.getAttribute("src") != manager_src)) {
          el.load()
        }
        // _this.videoManager = el
        _this.playByManager(el)
      })
    })
    const el = _this.getScrollEl()
    document.addEventListener('touchstart', function(){
      const size = isLine ? el.scrollTop : el.scrollLeft
      const currentPage = _this.mathPage(size)
      if(!currentPage) return false;
      if(_this.visible){
        _this.playBgm(currentPage[0])
      }
    })
    // document.addEventListener('touchend',()=>{
    //   setTimeout(()=>{
    //     this.showTap = true
    //   },5000)
    // })
  },
  methods: {
    logic(msg) {
      const el = document.getElementById("log")
      if(!el) return false;
      el.prepend(Date().toString() + ':' +msg,document.createElement('br'))
    },
    // 根据页码播放背景音乐
    playBgm(pageKey) {
      const elId = this.char.indexOf(pageKey) > 7 ? "bgm2" : 'bgm1'
      const current = document.getElementById(elId)
      // 切换背景音乐
      if(this.bgmManager) {
        if(this.bgmManager.getAttribute('src') == current.getAttribute("src")) return false;
        this.bgmManager.pause()
        console.log('bgm pause',this.bgmManager.getAttribute('src'))
      }
      if(!this.videoMuted) {
        if(this.isPlaying) {
          current.volume = 0.3
        }else {
          current.volume = 1
        }
        current.play()
        console.log('bgm play',current.getAttribute("src"))
      }
      this.bgmManager = current
    },
    // 暂停/播放 背景音乐
    videoStateChange(state){
      if(!state) {
        this.bgmManager.pause()
      }else {
        this.bgmManager.play()
        this.bgmManager.volume = 1
      }
      this.videoMuted = state
    },
    // 处理需要自动播放的音乐
    playByManager(el){
      const videoManager = this.videoManager
      const currentUri = el.getAttribute("src")
      if(videoManager) {
        const videoManagerUrl = videoManager.getAttribute("src")
        if(currentUri == videoManagerUrl && !videoManager.paused ) {
          console.log('pass playing')
          return false;
        }
        if(currentUri == this.lastPlaySrc) {
          console.log('pass played')
          return false;
        }
      }
      try {
        if(this.tapManager) {
          if(this.is_ios) {
            this.tapManager.pause()
          }else {
            let lowLevel = 10
            const videoInterval = setInterval(()=>{
              lowLevel = lowLevel - 1
              if(lowLevel <= 1) {
                this.tapManager.volume = 0
                this.tapManager.pause()
                return window.clearInterval(videoInterval)
              }
              this.tapManager.volume = lowLevel / 10
            },200)
          }

        }
        el.currentTime = 0
        el.volume = 1
        el.play()
        this.isPlaying = true
        this.videoManager = el
        this.logic('play success' + currentUri)
        el.addEventListener('ended',(e)=>{
          this.isPlaying = false
          this.lastPlaySrc = e.target.getAttribute("src")
        })
      }catch (e) {
        this.logic('play error' + e.message)
        console.log('play error',e)
      }
    },
    // 处理手动播放的音乐
    playById(id) {
      const is_ios = this.is_ios
      let other_playing = false
      // el.target.src = require('../assets/images/playing.png')
      document.querySelectorAll("audio").forEach(item=> {
        const videoKey = item.getAttribute("id")
        const musicType = item.getAttribute("data-type")
        // 暂停其他音频
        if (videoKey != id && !item.paused &&  musicType != 'bgm') {
          other_playing  = true
          if(is_ios) {
            item.pause()
          }else {
            let lowLevel = 10
            const videoInterval = setInterval(()=>{
              lowLevel = lowLevel - 1
              if(lowLevel <= 1) {
                item.volume = 0
                item.pause()
                return window.clearInterval(videoInterval)
              }
              item.volume = lowLevel / 10
            },200)
          }
        }
      })
      const videoEl = document.getElementById(id)
      if(!videoEl.paused) {
        // 再次点击 暂停播放
        this.isPlaying = false
        return videoEl.pause()
      }
      videoEl.play()
      if(other_playing) {
        // 存在其它播放音乐的情况下,渐进式播放当前音乐
        if(is_ios) {
          videoEl.volume = 1
        }else {
          let lowLevel = 0
          const videoInterval = setInterval(()=>{
            lowLevel = lowLevel + 1
            if(lowLevel >= 10) {
              videoEl.volume = 1
              return window.clearInterval(videoInterval)
            }
            videoEl.volume = lowLevel / 10
          },200)
        }
      }else {
        videoEl.volume = 1
      }

      this.tapManager = videoEl
      this.isPlaying = true
      videoEl.addEventListener('ended',(e)=>{
        this.isPlaying = false
        this.lastPlaySrc = e.target.getAttribute("src")
        this.tapManager = null
        console.log('play end',this.lastPlaySrc)
      })
    },
    // 初始化每个图片的宽度   [可视区开始高度,结束高度,dom的key,dom结构]
    initPage(){
      let begin = 0
      let conf = {}
      let pageOffset = {}
      let visitedKey = {}
      let pageOffsetItem = 0
      const sceenLight = this.isLine ? window.innerHeight : window.innerWidth
      const offset = Math.ceil(sceenLight * this.offset)
      document.querySelectorAll(".page-item").forEach(item=>{
        const key = item.getAttribute("data-pn")
        const width = item.clientWidth
        const widthEnd = begin + width - offset
        conf[`${key}_${widthEnd}`] = [key,item]
        begin = widthEnd
        visitedKey[key] = false

        pageOffsetItem += width
        pageOffset[key] = pageOffsetItem
      })
      this.pageConf = conf
      this.pageOffset = pageOffset
      this.visitedKey = visitedKey
    },
    // 根据滚动距离,获取可视dom结构
    mathPage(size) {
      const pageConfig = this.pageConf
      for (let i in pageConfig) {
        const ii = i.split('_')[1]
        if(size < ii) return pageConfig[i]
      }
      return false;
    },
    getScrollEl(){
      if(!this.isLine) {
        return document.querySelector(".content")
      }else {
        // 安卓 QQx5内核的bug  包括qq浏览器和微信内置浏览器
        const is_android = isAndroid()
        const is_qq = isQQBrowser()
        if(is_android) {
          if(isWeChat() || is_qq) {
            return document.body
          }
        }
        return document.querySelector("html")
      }
    },
    // 监听滚动区
    watchScroll() {
      const _this = this
      const delay = 100
      const el = this.getScrollEl()
      if(!this.isLine) {
        document.querySelector(".content").addEventListener('scroll',throttle(()=>_this.scrollHandler(el.scrollLeft,el.scrollWidth),delay),false)
      }else {
        document.addEventListener('scroll',throttle(()=>_this.scrollHandler(el.scrollTop,el.scrollHeight),delay),false)
      }
    },
    scrollEnd(){
      if(this.pn2Style != '') {
        console.log('pn1 scroll end')
        this.animationRun()
      }
    },
    // 滚动监听事件
    scrollHandler(size,fullSize = 0) {
      this.showTap = false
      const endOffset = 150
      this.oldSize = size
      if(!this.isLine) {
        if((fullSize - parseInt(size) - window.innerWidth) <= endOffset) {
          this.scrollEnd()
        }
      }else {
        if((fullSize - parseInt(size) - window.innerHeight) <= endOffset) {
          this.scrollEnd()
        }
      }
      const pageInfo = this.mathPage(size)
      const visitedKey = this.visitedKey
      if(!pageInfo) return false;
      const [key,el] = pageInfo
      if(key == this.currentKey) return false;
      // 开始处理
      for (let i in visitedKey) {
        if(i <= key) {
          // 仅生效一次的事件
          if(!visitedKey[i]) {
            this.pageAnimateHandler(key,el)
            this.pageAudioHandler(key,el)
            visitedKey[i] = true
          }
          // 重复监听的事件
        }
      }
      this.currentKey = key
    },
    swiperChange(item){
      //
      this.activeSlider = item
    },
    // 开国大典动画处理
    animationRun(){
      const bgc_el = document.getElementById("bgc");
      bgc_el.classList.remove("hidden")
      document.body.style.overflow = 'hidden'
      document.getElementById("app").style.height = '100vh'

      const scrollEl = this.getScrollEl()
      const offsetChange = ()=>{
        const offsetH = this.pageOffset['ga']
        if(!this.isLine) {
          scrollEl.scrollLeft = offsetH
        }else {
          scrollEl.scrollTop = offsetH
        }
      }
      // 加个延迟,等待用户滑动结束
      setTimeout(()=>offsetChange(),100)
      const video = document.getElementById("v8")
      const elEvent = ()=>{
        this.pn2Style = ""
        this.initPage()
        this.videoManager = video
        this.playByManager(video)
        bgc_el.classList.add("fadeout")
        offsetChange()
        setTimeout(function (){
          bgc_el.classList.add("hidden")
          document.body.style.overflow = 'inherit'
          bgc_el.classList.remove("fadeout")
          document.querySelector(".kaiguodd").classList.add("fade-filter")
        },1300)
      }
      bgc_el.addEventListener('click',elEvent)
      bgc_el.addEventListener('touchstart',elEvent)
    },
    // 处理page中音频
    pageAudioHandler(key,el) {
      const audio_dom = el.querySelector("audio")
      if(audio_dom && this.tapManager) {
        const current_audio = audio_dom.getAttribute("src")
        const playing_audio = this.tapManager.getAttribute("src")
        if(this.isPlaying && (current_audio != playing_audio)) {
          if(this.is_ios) {
            this.tapManager.pause()
          }else {
            let lowLevel = 10
            const videoInterval = setInterval(()=>{
              lowLevel = lowLevel - 1
              if(lowLevel <= 1) {
                this.tapManager.volume = 0
                this.tapManager.pause()
                return window.clearInterval(videoInterval)
              }
              this.logic('audio volume change ' + lowLevel )
              this.tapManager.volume = lowLevel / 10
            },200)
          }
          this.isPlaying = false
        }
      }
    },
    // 处理page中动画
    pageAnimateHandler(key,el) {
      if(key == 'ga') {
        // 风吹沙转场
        // this.animationRun()
      }
      else if(key == 'i') {
        // document.querySelector(".cover-sha").classList.add("fade-out-left")

      } else if(key == 'h') {
        this.$refs.swiper.play()
      }else if(key == 'l') {
        document.querySelector("#ship1").classList.add("ship")
        document.querySelector("#ship2").classList.add("ships")
      }

      if(el.getAttribute("data-animate")) {
        const style = el.getAttribute("data-animate")
        el.classList.remove(style)
        el.classList.remove("hidden")
        el.classList.add('animated')
        el.classList.add(style)
      }

      // el.querySelectorAll("[data-animate]").forEach(item=>{
      //   const style = item.getAttribute("data-animate")
      //   console.log('parseitem',item)
      //   item.classList.remove(style)
      //   item.classList.remove("hidden")
      //   item.classList.remove("filter")
      //   item.classList.add('animated')
      //   item.classList.add(style)
      // })
    },
    // 重新播放音频

  },
  watch: {
    loaded: function (e){
      if(e) this.initPage()
    },
    oldSize(val){
      setTimeout(()=>{
        const el = this.getScrollEl()
        const size = this.isLine ? el.scrollTop : el.scrollLeft
        if(size == val) {
          this.showTap = true
        }
      },1000)
    },
    visible: function (e){
      if(e) {
        const el = document.querySelector("[data-pn='a']")
        this.watchScroll()
        el.classList.add('animated')
        el.classList.add('fadeInRight')
        this.playBgm('a')
        this.$refs.slider.setAutoPlay()
      }
    },
    videoManager: function (val, oldVal) {
      if(val && oldVal) {
        oldVal.pause()
        if(this.is_ios) {
          oldVal.pause()
        }else {
          let lowLevel = 10
          const videoInterval = setInterval(()=>{
            lowLevel = lowLevel - 1
            if(lowLevel <= 1) {
              oldVal.volume = 0
              oldVal.pause()
              return window.clearInterval(videoInterval)
            }
            oldVal.volume = lowLevel / 10
          },200)
        }
      }
    },
    isPlaying: function (val,oldVal) {
      let lowLevel = 8
      // const is_ios = this.is_ios
      if(this.bgmManager) {
        console.log('video state change',val,oldVal)
        if(val) {
          // if(is_ios) {
            // this.$refs.slider.pauseAll()
            // return this.bgmManager.pause()
          // }
          // if(val && !oldVal ) {
          this.logic('audio volume change ' + lowLevel )
          this.bgmManager.volume = lowLevel / 10
        }
        // 暂停播放
        if(!val) {
          // if(is_ios) {
          //   this.$refs.slider.setAutoPlay()
            // return this.bgmManager.play()
          // }
        // if(!val && oldVal) {
          // 渐进式重新播放
          const videoInterval = setInterval(()=>{
            lowLevel = lowLevel + 1
            if(lowLevel >= 10) {
              this.bgmManager.volume = 1
              return window.clearInterval(videoInterval)
            }
            this.logic('audio volume change ' + lowLevel )
            this.bgmManager.volume = lowLevel / 10
          },300)
        }
      }
    },

  },
  props: {
    // 控制是否显示
    visible: {
      default: false,
    },
    // 是否加载完毕
    loaded: {
      default: false
    }
  }
}
</script>

<style scoped>
@keyframes toLong {
  0% { transform: scale(1.0) }
  50% { transform: scale(1.1) }
  100% { transform: scale(1.0) }
}
.tap-icon {
  animation: toLong 2s infinite;
}

.tap-wrap-line {
  /*margin-left: 15px;*/
  transform-origin: right;
  transform: rotate(90deg);
  position: fixed;
  z-index: 20;
  left: 0;
  display: flex;
  justify-content: center;
  /*animation: toLong1 2s infinite;*/
}
.tap-wrap {
  position: fixed;
  z-index: 20;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  bottom: 15px;
  /*animation: toLong 2s infinite;*/
}
.content-wrap {
  display: flex;
}
.page-item {
  position: relative;
  z-index: 2;
}
.font-wrap {
  position: absolute;
  top: 0;
  word-break: keep-all;
  overflow: hidden;
  right: 0;
}
.font-wrap img {
  width: 100%;
}

.cover-sha {
  position: absolute;
  top: 0;
  height: 390px;
  z-index: 3;
  opacity: 95%;
  animation-duration: 8s;
  overflow: hidden;
}
.fade-out-left {
  animation: shaFadeOut 15s forwards ;
  animation-timing-function: cubic-bezier(0.21, 0.49, 0.83, 0.83);
  filter: opacity(0.95);
}
@keyframes shaFadeOut {
  0% { filter: opacity(0.95); }
  30% { filter: opacity(0.8); }
  50% { filter: opacity(0.7); }
  100% { width: 0;opacity: 0 }
}
.play-icon1 {
  position: absolute;
  z-index: 3;
  right: 1%;
  top: 30%;
}
.play-icon1 img {
  width: 120px;
  transform: rotate(-16deg);
}
.first-item {
  opacity: 0;
  animation-duration: 1s;
}
.filter {
  filter: grayscale(1)
}
.step-title {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 30px;
  font-size: 20px;
  font-weight: 600;
  font-family: serif;
  letter-spacing: 3px;
  color: #b18913;
}
.item12-c1 {
  position: absolute;
  top: 0;
}
.ship {
  animation: ship 10s infinite;
  animation-timing-function: cubic-bezier(0.21, 0.15, 0.61, 0.73);
}
@keyframes ship {
  from { transform: translateX(10px); }
  to { transform: translateX(-45px); }
}
.ships {
  animation: ships 16s infinite;
  animation-timing-function: cubic-bezier(0.21, 0.15, 0.61, 0.73);
  /*animation-fill-mode: forwards;*/
}
@keyframes ships {
  from { transform: translateX(158px); }
  to { transform: translateX(0px); }
}
#log {
  position: fixed;
  top: 0;
  z-index: 99999;
  right: 0;
  word-break: break-all;
  background: #fff;
  font-size: 12px;
  max-height: 170px;
  overflow: scroll;
  width: 50%;
}
#log p{
  margin: 0 !important;

}
#bgc {
  position: fixed;
  z-index: 9999;

}
.bgc {
  background-repeat-x: repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
}
.bgc-line {
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.fadeout {
  animation: coverOut 1s forwards;
  animation-timing-function: cubic-bezier(0.21, 0.15, 0.61, 0.73);
}
@keyframes coverOut {
  0% { opacity: 1.0}
  45% { opacity: 0.8 }
  90% { opacity: 0.6 }
  100% { opacity: 0; }
}
</style>
